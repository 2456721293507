import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Card } from 'react-bulma-components';

const DailyStatsCard = ({ stats, title, cumulativeRatio }) => {
  return (
    <Card >

    <Card.Header>
        <Card.Header.Title>{title}</Card.Header.Title>
    </Card.Header>

  <Card.Content>
  <p>Win/Loss Ratio: {stats.winLossRatio}</p>
  <p>Wins {stats.totalWins} / Losses  {stats.totalLosses}</p>
  <p>Cumulative Win/Loss:  {cumulativeRatio}</p>

  </Card.Content>
    </Card>
  )
};

export default DailyStatsCard

