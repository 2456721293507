import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import StatsCard from './stats_card';
import DayRecords from '../DayRecords';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Button } from 'react-bulma-components';
import * as ROUTES from '../../constants/routes';

import {BrowserRouter as Router,
Link,
Route,
Switch,
} from 'react-router-dom';
import OverallStatsCard from './overall_stats_card';
import GameStatsCard from './game_stats_card';

const DailyStats = () => <h1>DailyStats</h1>;

class Scoreboard extends Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        stats: {},
        showAll: false

      };
      this.handleClick = this.handleClick.bind(this);

    }
 
    componentDidMount() {

      fetch('https://services.sledsoft.com/cod-tracker/api/stats/overall')
      .then(res => res.json())
      .then((data) => {
        console.log(data)
        this.setState({ 
          stats: data, 
          loading: false,
        })
      })
      .catch(console.log)
 
      }

      handleClick() {
        this.setState(state => ({
          showAll: !state.showAll
        }));
      }

    render() {
        const {stats, loading, showAll } = this.state;
      return (
         <div>
          {loading ? (
            <div>loading</div>       
            ):
            (
              <div>

              <Link to={ROUTES.DAILYSTATS}>All Daily Records</Link>
              <GameStatsCard stats={stats.mwStats} title="Modern Warfare" mostRecentRecord={stats.mwStats.mostRecentRecord} />  
              <GameStatsCard stats={stats.bocwStats} title="Black Ops Cold War " mostRecentRecord={stats.bocwStats.mostRecentRecord} />  
              <GameStatsCard stats={stats.overall} title="Overall " mostRecentRecord={stats.overall.mostRecentRecord} />  

                {/* <OverallStatsCard stats={stats.overall} dataPoints={stats.dataPoints} title="Overall Stats" highestRatio={stats.highestWinLossRatio} mostRecentRecord={stats.mostRecentRecord} />  
                <GameStatsCard stats={"0-0"} dataPoints={[]} title="Black Op Cold War Overall Stats" highestRatio={"0"} mostRecentRecord={"0-0"} />  
                <GameStatsCard stats={"0-0"} dataPoints={[]} title="Black Op Cold War Stats 6 People" highestRatio={"0"} mostRecentRecord={"0-0"} />  
                <GameStatsCard stats={"0-0"} dataPoints={[]} title="Black Op Cold War Stats 5 People" highestRatio={"0"} mostRecentRecord={"0-0"} />  
                <GameStatsCard stats={"0-0"} dataPoints={[]} title="Black Op Cold War Stats 4 People" highestRatio={"0"} mostRecentRecord={"0-0"} />   */}

{/* 
              {stats.byMonth.map((monthStats) =>
              <StatsCard stats={monthStats.stats} title={monthStats.month + " " + monthStats.year}/> 
             )
             }    */}
            
            </div>

             
           ) 
      }
        </div>
    );
}
}
 
export default withFirebase(Scoreboard);
 