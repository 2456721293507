import React, { Component } from "react";
import CanvasJSReact from "./canvasjs.react";
// var CanvasJS = CanvasJSReact.CanvasJS;
import moment from 'moment-timezone';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class WinLossChart extends Component {

 
 
	render() {
		const { dataPoints } = this.props
		
		const options = {
			animationEnabled: true,
			height:100,
			width:300,
			title:{
			},
			

			toolTip: {
				shared: true,
				contentFormatter: function (e) {
					var content = " ";
					for (var i = 0; i < e.entries.length; i++) {
						content += e.entries[i].dataPoint.label + " " + "<strong>" + e.entries[i].dataPoint.y + "</strong>";
						content += "<br/>";
					}
					return content;
				}
			},
			
			axisX: {
				title: "",
				tickLength: 0,
				lineThickness:0,
				margin:0,
				valueFormatString:" ",
				labelMaxWidth:0,
				labelFormatter: function ( e ) {
					return "";  
			    }  
			 
			},
			axisY: {
				title: "",
				tickLength: 0,
				lineThickness:0,
				margin:0,
				valueFormatString:" ",
				gridThickness:0,
				minimum: 1.25,
				maximum: 2.7

			},
			data: [{
				 yValueFormatString: "#.##",
				// xValueType: "dateTime",
				//name:"W/L Ratio",
			 	//xValueFormatString: "MMMM DD YYYY HH:mm:ss",
				type: "spline",
				lineThickness:1,
				lineColor:"red",
				markerType:"none",

				dataPoints: dataPoints						
			}]
	}

		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}
export default WinLossChart;
