import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Card } from 'react-bulma-components';
import WinLossChart from '../WinLossChart';


const GameStatsCard = ({ stats, title, mostRecentRecord }) => {
//const GameStatsCard = ({ stats, highestRatio, title, dataPoints, mostRecentRecord }) => {
  return (
    <Card >

    <Card.Header>
        <Card.Header.Title>{title}</Card.Header.Title>
    </Card.Header>

  <Card.Content>
  <p>W/L Ratio {stats.winLossRatio}</p>
  <p>Wins {stats.totalWins} / Losses  {stats.totalLosses}</p>
  <p>Most Recent Record: {mostRecentRecord}</p>
{/* 
  <p>Highest Ratio: {highestRatio}</p>
  <WinLossChart dataPoints={dataPoints}></WinLossChart>  */}
  </Card.Content>
    </Card>
  )
};

export default GameStatsCard

