import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Stats from '../Scoreboard/stats_card';
import DailyStatsCard from '../Scoreboard/daily_stats_card';

class DayRecords extends Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        stats: []
      };
    }
 
    componentDidMount() {

      fetch('https://services.sledsoft.com/cod-tracker/api/stats/allDaily')
      .then(res => res.json())
      .then((data) => {
        console.log(data)
        this.setState({ 
          stats: data, 
          loading: false,
        })
      })
      .catch(console.log)
 
      }

    render() {
        const {stats, loading } = this.state;
      return (
         <div>
          {loading ? (
            <div>loading</div>       
            ):
            (
              <div>
              {stats.dailyStats.map((stats) =>
              <DailyStatsCard stats={stats.stats} title={stats.month + " " + stats.day + " " + stats.year} cumulativeRatio={stats.cumulativeRatio}/> 
             )}   
            
            </div>
           ) 
      }
        </div>
    );
}
}
 
export default withFirebase(DayRecords);