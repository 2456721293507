import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import ScoreBoard from '../Scoreboard';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Button } from 'react-bulma-components';
import { withFirebase } from '../Firebase';

import DatePicker from 'react-date-picker';


  class HomePage extends Component {

    constructor(props) {
      super(props);
    }

    state = {
      date: new Date(),
    }
   
    onChange = date => this.setState({ date })
   
    
    componentDidMount() {
      // this.props.firebase.users().on('value', snapshot => {
      //   const usersObject = snapshot.val();
      //   const usersList = Object.keys(usersObject).map(key => ({
      //     ...usersObject[key],
      //     uid: key,
      //   }));
      
     //.db.ref('shared').on;
    }


    render() {

       
      return (
    <AuthUserContext.Consumer>
  {authUser => (
      <div>
      <p >Account: {authUser.email}</p>

      <DatePicker
          onChange={this.onChange}
          value={this.state.date}
        />
   
 
   
      <ScoreBoard></ScoreBoard>
    </div>
  
  )}
</AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);